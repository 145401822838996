import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { WINDOW } from '@ng-web-apis/common';
import { loadScript } from '@utils/load-script';
import { EnvConfigService } from './env-config.service';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  lastPage: string;

  constructor(
    private title: Title,
    private envConfig: EnvConfigService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window
  ) {}

  setTitle(pageTitle: string) {
    this.title.setTitle(pageTitle);
    const url = this.window.location.href;
    if (this.lastPage === undefined) {
      // first page load
      loadScript(this.document.head, {
        src: this.envConfig.getEnvConfig().firstHiveUrl,
        async: true,
        defer: true,
      });
      this.lastPage = url;
    } else {
      // Track page view
      // eslint-disable-next-line @typescript-eslint/dot-notation
      const push = this.window['_paq']?.push; // low level interface with analytics
      if (pageTitle && url !== this.lastPage && push) {
        push(['setReferrerUrl', this.lastPage]);
        push(['setCustomUrl', url]);
        push(['setDocumentTitle', pageTitle]);
        push(['trackPageView']);
        this.lastPage = url;
      }
    }
  }
}
