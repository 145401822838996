<!-- start categories box -->
<div class="main-article-list">
  <div class="knowledge-box categories inner-categories" id="Learning">
    <!-- start categories dropdown -->
    <div class="category-dropdown mb-5">
      <div class="dropdown">
        <button class="btn dropdown-toggle" type="button" (click)="toggleDropdown()">
          {{ dropdownTitle === 'smart tax planning' ? "Guide to Income Tax Planning" : dropdownTitle | titlecase }} <img
            alt="Click to open dropdown" src="assets/images/knowledge-center/arrowdown.svg">
        </button>
        <div *ngIf="isDropdownOpen">
          <app-knowledge-center-modal [selectedValue]="pageType"></app-knowledge-center-modal>
        </div>
      </div>
    </div>
    <!-- start categories filter block -->
    <div class="filter-block pt-md-3">
      <div class="d-flex justify-content-between">
        <div>
          <div class="filter-options mt-0 mb-4">
            <div class="form-group ">
              <input type="text" class="form-control search"
                     placeholder="Search articles, videos, blogs, podcasts, & e-books..." [(ngModel)]="searchTerm"
                     (input)=" searchTerm.length < 3 ? fundsForSearch = [] : filterFunds(searchTerm)">
              <div class="search-result" *ngIf="fundsForSearch?.length">
                <div class="inner">
                  <ng-container *ngFor="let fund of fundsForSearch">
                    <div class="funds-seach-title" (click)="getTableTitle(fund)"
                         style="position: relative; z-index: 999999;">
                      {{ fund.pageTitle }}
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="search-result" *ngIf="!fundsForSearch?.length && noSearchFlag">
                <div class="inner">
                  <ng-container>
                    <div class="funds-seach-title" style="position: relative; z-index: 999999;">
                      {{ "literature.literature-listing-no-search-suggestion" | translate }}
                      "{{ searchTerm }}"
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div>
              <img alt="" src="./assets/images/comparefund/closebutton.svg" class="make-tag-for-all-page d-md-none"
                   (click)="resetFundExplore()" style="
                            position: relative;
                            left: 78%;
                            top: -31px;">
              <img alt="" src="./assets/images/comparefund/closebutton.svg"
                   class="make-tag-for-all-page d-none d-md-block" (click)="resetFundExplore()" style="
                               position: relative;
                               left: 93%;
                               top: -26px;">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- start selected category btn -->
    <div class="category-btns">
      <ul class="category-list" *ngIf="pageType !== 'blog' && relatedTag === ''">
        <ng-container *ngFor="let category of categories">
          <li [class.active]="selectedCategory === category" class="fontResize" (click)="selectCategory(category)">{{
                  category
            }}
          </li>
        </ng-container>
      </ul>
      <ul class="category-list" *ngIf="pageType === 'blog' && relatedTag === ''">
        <li [class.active]="selectedCategory === 'All'" class="fontResize" (click)="selectBlogCategory('All')">All</li>
        <ng-container *ngFor="let category of blogCategory">
          <li [class.active]="selectedCategory === category" class="fontResize" (click)="selectBlogCategory(category)">
            {{
                  category
            }}
          </li>
        </ng-container>
      </ul>
    </div>

    <div class="row gx-md-4 gx-3" *ngIf="commonData.length !== 0">
      <div class="col-lg-3 col-md-4 col-12 mt-2" *ngFor="let card of commonData; let i = index">
        <div id="{{ card?.data?._id }}">
          <div class="category-card" style="cursor: pointer"
               (click)="setAnalytics(card?.pageTitle, card?.data?._source?.documentType)">
            <div class="card-img" type="button" (click)="categoryOnClick(card)">
              <img src="{{ card?.image ?  card?.image : defaultArticleBannerImage }}" alt="{{ card?.pageTitle }}"
                   title="{{ card?.pageTitle }}">
              <span class="ebook-icon" *ngIf="card?.pdfUrl">
                <img alt="Open PDF" src="assets/images/knowledge-center/ebook-icon.svg">
              </span>
              <span class="time-tag" *ngIf="card?.pdfUrl && card?.data?._source?.referenceDate !== ''">
                {{ getFormatedDate(card?.data?._source?.referenceDate) }}
              </span>
            </div>
            <div class="card-body">
              <div class="d-flex"
                   [ngClass]="card?.data?._source?.videoTimeStamp?'justify-content-between':'justify-content-end'">
                <span class="time-tag" *ngIf="card?.data?._source?.videoTimeStamp !== ''">{{
                        card?.data?._source?.videoTimeStamp
                  }}</span>
                <div class="card-icons">
                  <span class="img-with-tooltip me-3" (click)="toggleIconForWatchlist(card)" type="button">
                    <!-- <i class="bi bi-bookmark"></i> -->
                    <i [ngStyle]="{'display': !card?.isWatchListed ? 'inline' : 'none'}" class="bi bi-bookmark"></i>
                    <i *ngIf="card?.isWatchListed" class="bi bi-bookmark-fill"></i>
                    <span [ngStyle]="{'display': !card?.isWatchListed ? 'inline' : 'none'}"
                          class="tooltip-block">Save</span>
                    <span *ngIf="card?.isWatchListed" class="tooltip-block">Saved Course</span>
                  </span>
                  <!-- <span><i class="bi bi-reply"></i></span> -->
                  <span class="dropdown">
                    <span class="img-with-tooltip" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img alt="" src="assets/images/knowledge-center/share.svg" (mouseenter)="showTooltip = true"
                           (mouseleave)="showTooltip = false" (click)="toggleTooltip()"
                           class="d-inline-block mobile-icons light-mode-icon" style="width: 18px;">
                      <img alt="" src="assets/images/dark/share-dark-icon.png" (mouseenter)="showTooltip = true"
                           (mouseleave)="showTooltip = false" (click)="toggleTooltip()"
                           class="mobile-icons dark-mode-icon"
                           style="width: 21px; top: 7px; position: relative; left: 0;">
                      <span *ngIf="showTooltip" class="tooltip-block">share</span>
                    </span>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" (click)="toggleShowCopyDialoge(true,card)"><img alt="Copy"
                                                                                                   class="share-icon"
                                                                                                   src="assets/images/general/Link.webp"><span
                          class="action-icon">{{
                              "products.home-fund-card-share-copy-btn" | translate
                        }}</span></a></li>
                      <li><a class="dropdown-item" (click)="shareOnApp('TWITTER',card)"><img alt="Twitter"
                                                                                             class="share-icon"
                                                                                             src="assets/images/general/twitter-new-icon.webp"
                                                                                             style="width: 20px;"><span
                          class="action-icon">{{
                              "products.home-fund-card-share-twitter-btn"
                                  | translate
                        }}</span></a>
                      </li>
                      <li><a class="dropdown-item" (click)="shareOnApp('FACEBOOK',card)"><img alt="Twitter"
                                                                                              class="share-icon"
                                                                                              src="assets/images/general/FacebookLogo.webp"><span
                          class="action-icon">{{
                              "products.home-fund-card-share-fb-btn" |
                                  translate
                        }}</span></a></li>
                      <li><a class="dropdown-item" (click)="shareOnApp('LINKEDIN',card)"><img alt="Twitter"
                                                                                              class="share-icon"
                                                                                              src="assets/images/general/LinkedinLogo.webp"><span
                          class="action-icon">{{
                              "products.home-fund-card-share-linkedin-btn" |
                                  translate
                        }}</span></a></li>
                      <li><a class="dropdown-item" (click)="shareOnApp('WHATAPP',card)"><img alt="Twitter"
                                                                                             class="share-icon"
                                                                                             src="assets/images/general/whatsapps.webp"><span
                          class="action-icon">{{
                              "products.home-fund-card-share-whatsapp-btn" |
                                  translate
                        }}</span></a></li>
                    </ul>
                  </span>
                </div>
              </div>
              <div (click)="categoryOnClick(card)" class="text-bold card-title mt-1 forlikecardtitle"
                   *ngIf="!(card?.pageTitle?.length > 35); else elseBlock">
                {{ card?.pageTitle }}
              </div>
              <ng-template #elseBlock>
                <div (click)="categoryOnClick(card)" class="text-bold card-title mt-1 forlikecardtitle"
                     *ngIf="card?.pageTitle?.length > 35">
                  {{ (card?.pageTitle.toString()?.substring(0, 35)).toLowerCase() }}...
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row gx-md-4 gx-3" *ngIf="commonData.length === 0">
      <div class="text-center mt-5">
        <h3>The category {{ dropdownTitle }} doesn't have any {{ selectedCategory }}.</h3>
      </div>
    </div>
    <div class="text-center mt-5">
      <button class="btn btn-white" *ngIf="showLoadMore" (click)="loadMore(selectedCategory)">Load more</button>
    </div>
  </div>
</div>

<!-- Dialoge: copy alert -->
<div class="save-alert copy-alert" *ngIf="showCopyDialoge">
  <div class="content">
    <span class="close-btn" (click)="toggleShowCopyDialoge(false,'')">
      <img alt="close" src="assets/images/general/close.webp">
    </span>
    <img alt="Tick" src="assets/images/general/check_circle.svg" class="checked-img">
    <div class="mb-3">{{ "products.home-fund-card-link-copied-label" | translate }}</div>
    <div class="link-box">{{ linkToCopy }}</div>
  </div>
</div>

<!-- Dialoge: Max allowed alert -->
<div class="save-alert" style="z-index:10000" *ngIf="watchListFull">
  <div class="content">
    <ng-container>
      <a class="close-btn" (click)="closeWatchlist()">
        <img alt='image' src="assets/images/general/close.webp">
      </a>
      <img alt='image' src="assets/images/general/check_circle.svg" class="checked-img">
      <span style="font-size: 1.063rem;">{{ "ftiKc.kc-watchlist-full" | translate }}</span>
    </ng-container>
  </div>
</div>
