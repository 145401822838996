<!-- start categories box -->
<div class="fd-tab-sections knowledge-box categories" id="releases">

  <!-- start ad box -->
  <div class="FT-ad-box">
    <div class="FT-title-medium text-bold mb-2 text-white">{{ tabTitle }}</div>
    <div class="text-big mb-lg-4 mb-3">{{ tabDescription }}</div>

  </div>

  <!-- start categories filter block -->
  <div class="filter-block">
    <div class="row justify-content-between">
      <div class="col-auto">
        <div class="filter-options mt-0 mb-4">
          <div class="form-group">
            <input type="text" placeholder="{{ 'products.add-fund-search-input-placeholder' | translate }}"
                   class="form-control search" [(ngModel)]="searchTerm" (input)="searchArticles(searchTerm)">
            <div class="search-result" *ngIf="showFundSearchDropdown && searchTerm && searchTerm.length >= 3">
              <div class="inner">
                <ng-container *ngFor="let article of releasesDataSearch">
                  <div class="funds-seach-title" (click)="showSelectedArticle(article)"
                       style="position: relative; z-index: 999999; cursor: pointer;">
                    <span class="fund-search-filter-title">{{ article?.title }} - {{ article?.year }}</span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div>
            <img alt="image" src="./assets/images/comparefund/closebutton.svg" class="make-tag-for-all-page share-icon2"
                 (click)="clearSearch()"
                 style="
                            position: relative;
                            left: 93%;
                            top: -31px;">
          </div>
        </div>
      </div>
      <div class="col-auto">
        <div class="dropdown me-3">
          <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
            {{ selectedSortItem?.value }}
          </button>
          <ul class="dropdown-menu">
            <ng-container *ngFor="let item of sortingOptions">
              <li style="cursor: pointer;" (click)="sortMedia(item)"
                  [class.selected-option]="item.key === selectedSortItem.key">
                <a class="dropdown-item">{{ item?.value }}</a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- start selected category btn -->
  <div class="category-btns">
    <div class="category-container">
      <ul>
        <li *ngFor="let year of yearsFilterDropdown" [class.active]="selectedYear === year"
            (click)="selectYear(year)">
          {{ year }}
        </li>
      </ul>
    </div>
  </div>

  <!-- start categories block -->
  <div class="category-block">
    <div class="table-responsive reports-box">
      <table class="table table-success table-striped">
        <thead>
        <tr>
          <th>{{ "products.pricing-date" | translate }}</th>
          <th>{{ "products.media-releases-table-heading2" | translate }}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let media of releasesData">
          <td class="text-nowrap">{{ media?.shortDate }}</td>
          <td>{{ truncateText(media.title) }}</td>
          <td class="text-end">
            <img style="cursor: pointer;" (click)="openLink(media)" alt="{{media?.fileType.toLowerCase()+ '.svg'}}"
                 [src]="'assets/images/home/' + media?.fileType.toLowerCase()+ '.svg'">
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>
