import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  Inject,
} from '@angular/core';
import { Component as BrComponent, Page, Document } from '@bloomreach/spa-sdk';
import { DomSanitizer } from '@angular/platform-browser';
import {
  NgbActiveModal,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from '@services/session.service';
import { Constant } from '../../ft-constants/constant';
import { Logger } from '@utils/logger';
import { TranslateService } from '@components/shared/translate/translate.service';
import { EnvConfigService } from '@services/env-config.service';
import { CommonService } from '@services/common.service';
import { TitleService } from '@services/title.service';
import { WindowService, WindowSize } from '@services/window.service';
import { AnalyticsService } from '@services/analytics.service';
import { WINDOW } from '@ng-web-apis/common';
import { Subject } from 'rxjs';

const logger = Logger.getLogger('CodeComponent');

@Component({
  selector: 'ft-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CodeComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  @ViewChild('container') container: ElementRef;
  modalRef: any;
  name = '';
  email = '';
  phone = '';
  city = '';
  url: string;
  otp: string;
  distributerFormData = {
    refNo: '',
    mobileNo: '',
    emailId: '',
    guId: '',
  };
  isFeederCampaignPage = false;
  isElssCampaignPage = false;
  isCampaignPage = false;
  isBlueChipCampignPage = false;
  utmParams = [];
  baseUrl = '';
  isPrimaFundPageCampignPage = false;
  isSmallCompaniesCampaignPage = false;
  rfbTfiPage = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(WINDOW) private _window: Window,
    private sanitizeHtml: DomSanitizer,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private titleService: TitleService,
    private translateService: TranslateService,
    private envConfig: EnvConfigService,
    private commonService: CommonService,
    private windowService: WindowService,
    private analyticsService: AnalyticsService
  ) {
  }

  ngOnChanges() {
  }

  ngOnInit(): void {
    this.updateAddThis();
    this.baseUrl = this.envConfig.getEnvConfig()?.baseUrl;
    if (location.href.includes(Constant.usFeederPage)) {
      this.isFeederCampaignPage = true;
      this.setPageTitle();
      this.getUrlParams();
    }
    if (location.href.includes(Constant.elssFundPage)) {
      this.isElssCampaignPage = true;
      this.setPageTitle();
      this.getUrlParams();
    }
    if (location.href.includes(Constant.campaign)) {
      this.isCampaignPage = true;
      this.getUrlParams();
    }
    // for blue chip campaign Page
    if (location.href.includes(Constant.blueChipPage)) {
      this.isBlueChipCampignPage = true;
      this.setPageTitle();
      this.getUrlParams();
    }
    // for prima blue campaign Page
    if (location.href.includes(Constant.primaFundPage)) {
      this.isPrimaFundPageCampignPage = true;
      this.getUrlParams();
    }
    if (location.href.includes(Constant.smallCompaniesPage)) {
      this.isSmallCompaniesCampaignPage = true;
      this.getUrlParams();
    }
    if (location.href.includes(Constant.tfi)) {
      this.rfbTfiPage = true;
    }
  }

  getUrlParams() {
    this.route.queryParams.subscribe((params) => {
      if (params) {
        for (let [key, value] of Object.entries(params)) {
          if (key && key.includes('amp;')) {
            const newKey = key.split('amp;');
            key = newKey ? newKey[1] : newKey[0];
            this.utmParams[key] = value;
          } else {
            this.utmParams[key] = value;
          }
        }
      }
    });
  }

  get document() {
    const {document} = this.component?.getModels<DocumentModels>() || {};
    return document && this.page.getContent<Document>(document);
  }

  public navigateToSection(section: string) {
    window.location.hash = '';
    window.location.hash = section;
  }

  get content() {
    return this.document?.getData();
  }

  get data() {
    return this.document?.getData<DocumentData>();
  }

  ngAfterViewInit() {
    this.updateAddThis();
    this.addMoreTag();
    this.sliderSection();
    const scrollToTop = document.querySelector('a.back-to-top');
    scrollToTop?.classList.add('d-none');
    scrollToTop?.addEventListener('click', () => {
      window.scrollTo(0, 0);
      scrollToTop.classList.add('d-none');
    });
    const popUp = document.querySelector('.reachForBetter .modal');
    const valimaniPopUp = document.querySelector(
      '.reachForBetter .custom-modal'
    );

    document.querySelectorAll('#csrCounters').forEach((item) => {
      this.setCounters(item, 0, 100, 5000);
    });
    window.onclick = (event) => {
      if (event.target.id === 'closeBtn') {
        this.modalService.dismissAll();
      }
    };
    const videoPopUp = document.querySelector('.hero-button');
    const videoPopUp1 = document.querySelector('.hero-button1');
    const videoVelumaniPopUp = document.querySelector('.custom-velumani');
    const videoVelumaniPopUp1 = document.querySelector('.custom-velumani1');
    const loadMoreWork = document.querySelector('.insights-card__blurb');
    const loadMoreGoqii = document.querySelector('.insights-card_goqii');
    videoPopUp?.addEventListener('click', () => {
      this.modalRef = this.modalService.open(NgbdModalContentComponent);
      this.modalRef.componentInstance.name = this.sanitizeHtml.bypassSecurityTrustHtml(// NOSONAR
        popUp.innerHTML
      );
    });
    videoPopUp1?.addEventListener('click', () => {
      this.modalRef = this.modalService.open(NgbdModalContentComponent);
      this.modalRef.componentInstance.name = this.sanitizeHtml.bypassSecurityTrustHtml(// NOSONAR
        popUp.innerHTML
      );
    });
    videoVelumaniPopUp?.addEventListener('click', () => {
      this.modalRef = this.modalService.open(NgbdModalContentComponent);
      this.modalRef.componentInstance.name = this.sanitizeHtml.bypassSecurityTrustHtml(// NOSONAR
        valimaniPopUp.innerHTML
      );
    });
    videoVelumaniPopUp1?.addEventListener('click', () => {
      this.modalRef = this.modalService.open(NgbdModalContentComponent);
      this.modalRef.componentInstance.name = this.sanitizeHtml.bypassSecurityTrustHtml(// NOSONAR
        valimaniPopUp.innerHTML
      );
    });
    loadMoreWork?.addEventListener('click', () => {
      loadMoreWork?.children[1].classList.remove('d-none');
      loadMoreWork?.children[2].classList.add('d-none');
    });
    loadMoreGoqii?.addEventListener('click', () => {
      loadMoreGoqii?.children[1].classList.remove('d-none');
      loadMoreGoqii?.children[2].classList.add('d-none');
    });
    const fbShareElems = document.querySelectorAll('.at-svc-facebook');
    fbShareElems.forEach((elem) => {
      elem.addEventListener('click', (e) => {
        e.preventDefault();
        const x = screen.width / 2 - 675 / 2;
        const y = screen.height / 2 - 374 / 2;
        window.open(
          (elem as any).href,
          '_blank',
          'width=675,height=374,left=' + x + ',top=' + y
        );
      });
    });
    const twitterShareElems = document.querySelectorAll('.at-svc-twitter');
    twitterShareElems.forEach((elem) => {
      elem.addEventListener('click', (e) => {
        e.preventDefault();
        const x = screen.width / 2 - 550 / 2;
        const y = screen.height / 2 - 449 / 2;
        window.open(
          (elem as any).href,
          '_blank',
          'width=675,height=374,left=' + x + ',top=' + y
        );
      });
    });
    if (
      this.isFeederCampaignPage ||
      this.isBlueChipCampignPage ||
      this.isCampaignPage ||
      this.isPrimaFundPageCampignPage ||
      this.isElssCampaignPage ||
      this.isSmallCompaniesCampaignPage
    ) {
      this.addContinueFormsListeners();
      this.continueDistributerForm();
      this.submitButton();
      this.addChangeAnnualReturn();
      this.resizeImage(window.screen.width);
    }
    this.handleCarousel();
    const projectValue = document.getElementById('csrCounters');
    // Track Window Scroll (Throttled)
    this.windowService
      .getScrollObs$()
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        const navbar = document.getElementById('left-nav');
        const philosophy = document.getElementById('nav-philosophy');
        const perspective = document.getElementById('nav-perspective');
        const pActive = document.getElementById('philosophy');

        const perActive = document.getElementById('perspective');
        const pTop = philosophy?.getBoundingClientRect().top;
        const pRes = perspective?.getBoundingClientRect().top;
        const goTopBtn = document.querySelector('.back-to-top');
        if (window.pageYOffset >= 250) {
          navbar?.classList.add('sticky');
          if (goTopBtn && goTopBtn.classList.contains('d-none')) {
            goTopBtn.classList.remove('d-none');
          }
        } else {
          navbar?.classList.remove('sticky');
          goTopBtn?.classList.add('d-none');
        }
        if (window.pageYOffset >= pTop && window.pageYOffset <= pRes + 40) {
          //logger.debug('inside ifffff');
          pActive?.classList.add('active');
          perActive?.classList.remove('active');
        } else if (window.pageYOffset >= pRes) {
          pActive?.classList.remove('active');
          perActive?.classList.add('active');
        } else {
          pActive?.classList.remove('active');
        }
      });

    // Track Window Resize (Throttled)
    this.windowService
      .getResizeObs$()
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((windowSize: WindowSize) => {
        const screenWidth = windowSize.width;
        this.resizeImage(screenWidth);
      });
  }

  public loadScript(url: string) {
    const body = document.body as HTMLDivElement;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }
  public setCounters(obj, start = 0, end, duration) {
    // let start=0;
    const endValue = obj.innerHTML;
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) {
        startTimestamp = timestamp;
      }
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML = Math.floor(progress * (endValue - start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  get title() {
    return this.page.getTitle();
  }

  get params() {
    return this.component.getParameters();
  }

  updateAddThis() {
    // tslint:disable-next-line
    const addThis = this._window['addthis'];
    if (addThis) {
      addThis.url = this._window.location.href;
      addThis.title = this._window.document.title;
      addThis.toolbox('.addthis_inline_share_toolbox');
    }

    if (addThis?.layers?.refresh) {
      addThis.layers.refresh();
    }
  }

  addMoreTag() {
    if (this.container !== undefined) {
      const listDivWrap = document.querySelectorAll('#mainNav li');
      let src;

      // tslint:disable-next-line
      for (let i = 0; i < listDivWrap.length; i++) {
        const script = listDivWrap[i];

        script.addEventListener('click', (e) => {
          src = script.getAttribute('name');
          if (src) {
            this.smoothScroll(src);
          }
        });
      }
    }
  }

  sliderSection() {
    if (this.container !== undefined) {
      const listDivWrap = document.querySelectorAll('#downArrow a');
      let src;

      // tslint:disable-next-line
      for (let i = 0; i < listDivWrap.length; i++) {
        const script = listDivWrap[i];

        script.addEventListener('click', (e) => {
          src = script.getAttribute('name');
          if (src) {
            this.smoothScroll(src);
          }
        });
      }
    }
  }

  smoothScroll(target) {
    let stra2 = document.querySelector(`#${target}`);
    //logger.debug('stra2: stra2:::', stra2);
    stra2 = stra2 === undefined ? document.querySelector(`#${target}`) : stra2;
    const doScrolling = (elementY, duration) => {
      const startingY = window.pageYOffset;
      const diff = elementY - startingY;
      let start;
      // Bootstrap our animation - it will get called right before next frame shall be rendered.
      window.requestAnimationFrame(function step(timestamp) {
        if (!start) {
          start = timestamp;
        }
        // Elapsed milliseconds since start of scrolling.
        const time = timestamp - start;
        // Get percent of completion in range [0, 1].
        const percent = Math.min(time / duration, 1);

        window.scrollTo(0, startingY + diff * percent);

        // Proceed with animation as long as we wanted it to.
        if (time < duration) {
          window.requestAnimationFrame(step);
        }
      });
    };

    if (stra2 != null) {
      doScrolling(stra2.getBoundingClientRect().top + window.scrollY - 45, 10);
    }
  }

  addContinueFormsListeners() {
    const nameElement = document.querySelector('#communication_Name');
    const phoneElement = document.querySelector('#communicationPhoneNumber');
    const EmailElement = document.querySelector('#communication_Email');
    const cityElement = document.querySelector('#city');
    nameElement?.addEventListener('change', () => {
      this.name = (document.getElementById(
        'communication_Name'
      ) as HTMLInputElement).value;
      this.isEnableFormSubmission();
    });
    phoneElement?.addEventListener('change', () => {
      this.phone = (document.getElementById(
        'communicationPhoneNumber'
      ) as HTMLInputElement).value;
      this.isEnableFormSubmission();
    });
    EmailElement?.addEventListener('change', () => {
      this.email = (document.getElementById(
        'communication_Email'
      ) as HTMLInputElement).value;
      this.isEnableFormSubmission();
    });
    cityElement?.addEventListener('change', () => {
      this.city = (document.getElementById('city') as HTMLInputElement).value;
      this.isEnableFormSubmission();
    });
  }

  isEnableFormSubmission() {
    if (this.name && this.phone && this.email && this.city) {
      (document.getElementById(
        'continue-btn'
      ) as HTMLInputElement).disabled = false;
      (document.getElementById(
        'continue-btn'
      ) as HTMLInputElement).style.backgroundColor = 'rgb(20, 60, 111)';
      (document.getElementById(
        'submit-btn'
      ) as HTMLInputElement).style.backgroundColor = 'rgb(20, 60, 111)';
    } else {
      (document.getElementById(
        'continue-btn'
      ) as HTMLInputElement).disabled = true;
    }
  }

  continueDistributerForm() {
    const continueBtn = document.querySelector('#continue-btn');
    continueBtn?.addEventListener('click', () => {
      const communicationName = (document.getElementById(
        'communication_Name'
      ) as HTMLInputElement).value;
      const communicationPhoneNumber = (document.getElementById(
        'communicationPhoneNumber'
      ) as HTMLInputElement).value;
      const communicationEmail = (document.getElementById(
        'communication_Email'
      ) as HTMLInputElement).value;
      const city = (document.getElementById('city') as HTMLInputElement).value;
      const isValidMobile = this.commonService.isValidMobile(
        communicationPhoneNumber
      );
      const isValidEmail = this.commonService.isValidEmail(communicationEmail);
      const phoneErr = document.querySelector<HTMLElement>(
        '.communicationPhoneNumber-error'
      );
      const emailErr = document.querySelector<HTMLElement>(
        '.communicationEmail-error'
      );
      if (!isValidMobile) {
        phoneErr.style.display = 'block';
        document.getElementsByClassName(
          'communicationPhoneNumber-error'
        )[0].innerHTML = this.translateService.instant(
          'ftiCommon.phoneInvalidmsg'
        );
      } else {
        phoneErr.style.display = 'none';
        document.getElementsByClassName(
          'communicationPhoneNumber-error'
        )[0].innerHTML = '';
      }
      if (!isValidEmail) {
        emailErr.style.display = 'block';
        document.getElementsByClassName(
          'communicationEmail-error'
        )[0].innerHTML = this.translateService.instant(
          'ftiCommon.emailInvalidMessage'
        );
      } else {
        emailErr.style.display = 'none';
        document.getElementsByClassName(
          'communicationEmail-error'
        )[0].innerHTML = '';
      }
      if (isValidMobile && isValidEmail) {
        this.distributerFormData = {
          refNo: '',
          mobileNo: communicationPhoneNumber,
          emailId: communicationEmail,
          guId: '',
        };
        this.sendOtp();
      }
    });
  }

  submitButton() {
    const submitBtn = document.querySelector('#submit-btn');
    const otpElement = document.querySelector('#communication_OTP');
    otpElement?.addEventListener('change', () => {
      this.otp = (document.getElementById(
        'communication_OTP'
      ) as HTMLInputElement).value;
    });
    const otpErr = document.querySelector<HTMLElement>(
      '.communication_OTP-error'
    );
    submitBtn?.addEventListener('click', () => {
      if (this.otp) {
        otpErr.style.display = 'none';
        document.getElementsByClassName(
          'communication_OTP-error'
        )[0].innerHTML = '';
        const data = {
          OTP: this.otp,
          guId: '',
        };
        this.sessionService.validateFeederOTP$(data).subscribe(
          (response) => {
            if (response) {
              this.analyticsService.trackEvent({
                event: 'All Pages',
                category: 'Ask For Distributor',
                action: 'Lead',
                label: 'GenerateOTP - Success ',
              });
              this.postDistributerFormData();
            }
          },
          (error) => {
            if (error && error.error[0]) {
              otpErr.style.display = 'block';
              document.getElementsByClassName(
                'communication_OTP-error'
              )[0].innerHTML = error.error[0].errorDescription;
            }
          }
        );
      } else {
        otpErr.style.display = 'block';
        document.getElementsByClassName(
          'communication_OTP-error'
        )[0].innerHTML = this.translateService.instant(
          'ftiCommon.otpRequiredMsg'
        );
      }
    });
  }

  handleOtpTimer() {
    let timeleft = 1;
    const self = this;
    const resendOtpElement = document.getElementsByClassName('adv-resend-otp');

    // resendOtpElement[0]?.addEventListener('click', resendClickHandler);
    function resendClickHandler() {
      const otpErr = document.querySelector<HTMLElement>(
        '.communication_OTP-error'
      );
      otpErr.style.display = 'none';
      document.getElementsByClassName('communication_OTP-error')[0].innerHTML =
        '';
      self.sendOtp();
    }

    const otpTimer = setInterval(() => {
      if (timeleft === 30) {
        clearInterval(otpTimer);
        document.getElementsByClassName('adv-otp-timer')[0].innerHTML = '';
        resendOtpElement[0].classList.add('adv-clk-enabled');
        resendOtpElement[0]?.addEventListener('click', resendClickHandler);
        (document.getElementById(
          'adv-otp-timer'
        ) as HTMLInputElement).style.display = 'none';
        // const resendOtpElemnt = document.getElementById('adv-resend-otp');
        // resendOtpElemnt.style.pointerEvents = 'revert';
      } else {
        (document.getElementById(
          'adv-otp-timer'
        ) as HTMLInputElement).style.display = 'block';
        resendOtpElement[0].classList.remove('adv-clk-enabled');
        resendOtpElement[0].removeEventListener('click', resendClickHandler);
        // const resendOtpElemnt = document.getElementById('adv-resend-otp');
        // resendOtpElemnt.style.pointerEvents = 'none';
        document.getElementsByClassName(
          'adv-otp-timer'
        )[0].innerHTML = `(Active in 00:${30 - timeleft})`;
      }
      timeleft += 1;
    }, 1000);
  }

  sendOtp() {
    if (this.distributerFormData.mobileNo) {
      this.sessionService.generateOtp$(this.distributerFormData).subscribe(
        (response) => {
          if (response && response.body.data.status === 'SUCCESSOTP') {
            document.getElementsByClassName(
              'communicationEmail-error'
            )[0].innerHTML = '';
            document.getElementById('communication_OTP').focus();
            const hideOTPSuccessElement = Array.from(
              document.getElementsByClassName('hideOTPSuccess')
            );
            hideOTPSuccessElement.forEach((element: any) => {
              element.style.display = 'none';
            });
            // add dynamic height
            [].forEach.call(
              document.querySelectorAll('.carousel-inner'),
              (el) => {
                el.style.marginBottom = '22px';
              }
            );

            document.querySelectorAll<HTMLElement>(
              '.advisor-otp-message'
            )[0].style.marginBottom = '35px';

            const hideElement = Array.from(
              document.getElementsByClassName('hide')
            );
            hideElement.forEach((element: any) => {
              element.style.display = 'block';
            });
            const advSuccessblockElement = Array.from(
              document.getElementsByClassName('adv-success-blk')
            );
            advSuccessblockElement.forEach((element: any) => {
              element.style.display = 'none';
            });
            document.getElementsByClassName(
              'advisor-otp-message'
            )[0].innerHTML = response.body.data.otpMessage;
            document.querySelectorAll<HTMLElement>(
              '.advisor-otp-message'
            )[0].style.marginBottom = '35px';
            this.handleOtpTimer();
          }
        },
        (error) => {
          if (error && error.error[0]) {
            let errorMessage = '';
            if (error.status === 500) {
              errorMessage = error.error[0].errorDescription;
            } else {
              errorMessage = this.translateService.instant(
                'ftiCommon.serviceUnavailableMsg'
              );
            }
            document.getElementsByClassName(
              'advisor-otp-message'
            )[0].innerHTML = errorMessage;
            const advisorOtpMessageElement = Array.from(
              document.getElementsByClassName('advisor-otp-message')
            );
            advisorOtpMessageElement.forEach((element: any) => {
              element.style.color = 'red';
              element.style.height = '35px';
            });
          }
        }
      );
    }
  }

  postDistributerFormData() {
    const utmCampaign = 'utm_campaign';
    const utmSource = 'utm_source';
    const utmMmedium = 'utm_medium';
    const payload = {
      name: this.name,
      mobile: this.phone,
      emailId: this.email,
      city: this.city,
      refNo: '',
      guid: '',
      campaignType: this.utmParams[utmCampaign] || '',
      source: this.utmParams[utmSource] || 'marketing-leads-form',
      medium: this.utmParams[utmMmedium] || '',
    };
    this.sessionService.postDistributeForm$(payload).subscribe(
      (response) => {
        if (response) {
          this.analyticsService.trackEvent({
            event: 'All Pages',
            category: 'Ask For Distributor',
            action: 'Lead',
            label: 'Submit',
          });

          document.getElementsByClassName('advisor-otp-message')[0].innerHTML =
            '';
          const advisorOtpMessageElement = Array.from(
            document.getElementsByClassName('advisor-otp-message')
          );
          advisorOtpMessageElement.forEach((element: any) => {
            element.style.marginBottom = '0px';
          });

          const advSuccessblockElement = document.getElementsByClassName(
            'adv-success-blk'
          );
          advSuccessblockElement[0].classList.remove('hide');
          const advSuccessBlk = document.querySelector<HTMLElement>(
            '.adv-success-blk'
          );
          advSuccessBlk.style.display = 'block';
          const hideElement = Array.from(
            document.getElementsByClassName('hide')
          );
          hideElement.forEach((element: any) => {
            element.style.display = 'none';
          });
          const successMsg = this.translateService.instant(
            'ftiCommon.fundCampaignSuccessMsg'
          );
          document.getElementsByClassName(
            'success-msg'
          )[0].innerHTML = successMsg;
        }
      },
      (error) => {
        if (error && error.error[0]) {
          const otpErr = document.querySelector<HTMLElement>(
            '.communication_OTP-error'
          );
          otpErr.style.display = 'block';
          document.getElementsByClassName(
            'communication_OTP-error'
          )[0].innerHTML = error.error[0].errorDescription;
        }
      }
    );
  }

  addChangeAnnualReturn() {
    const investmentElement = document.querySelector('#selectInvestment');
    investmentElement?.addEventListener('change', (event: any) => {
      document.getElementsByClassName('annualised_return_value')[0].innerHTML =
        event.target.value;
    });
  }

  handleCarousel() {
    const playIcons = document.querySelectorAll(
      '#myCarousel .icon-fti_play,#myCarousel .icon-fti_rightArrow, #myCarousel .icon-fti_leftArrow'
    );
    playIcons.forEach((item) => {
      item.innerHTML = '';
    });

    const h6Title = document.querySelector('.learnAndGrowContent h6');
    const knowMoreBtn = document.querySelector(
      '.learnAndGrowContent a.panel-orange-btn.analytics-link'
    );
    const contentElem = document.querySelector('.learnAndGrowContent .content');
    const videoElem = document.querySelector(
      '.learnAndGrowContent .videoWrapper iframe'
    );
    const prevArrow = document.querySelector('#myCarousel #left');
    const nextArrow = document.querySelector('#myCarousel #right');
    const carouselItems = document.querySelectorAll(
      '#myCarousel .carousel-inner .carousel-item'
    );
    const itemNum = carouselItems.length;
    const firstItem = carouselItems[0];
    const lastItem = carouselItems[itemNum - 1];
    const carouselItemList = document.querySelectorAll(
      '#myCarousel .carousel-inner .carousel-item .mediaItem.thumbnailVid a'
    );
    carouselItemList.forEach((item) => {
      item.addEventListener('click', (ev) => {
        ev.preventDefault();
        const videoId = item.getAttribute('data-video-id');
        const titleContent = item.nextElementSibling.querySelector('div.title')
          .innerHTML;
        const contentBlock = item.nextElementSibling.querySelector(
          'div.item-content'
        );
        const textContent = contentBlock.innerHTML;
        const redirectUrl = contentBlock.getAttribute('data-redirectlink');
        h6Title.innerHTML = titleContent;
        contentElem.innerHTML = textContent;
        knowMoreBtn.setAttribute('href', redirectUrl);
        let src = videoElem.getAttribute('src');
        src = src.split('embed/')[1].split('?')[1];
        const newSrc = 'https://www.youtube.com/embed/' + videoId + '?' + src;
        videoElem.setAttribute('src', newSrc);
      });
    });
    if (prevArrow) {
      prevArrow.addEventListener('click', () => {
        const activeItem = document.querySelector(
          '#myCarousel .carousel-inner .carousel-item.active'
        );
        activeItem.classList.add('right');
        setTimeout(() => {
          activeItem.classList.remove('active');
          activeItem.classList.remove('right');
        }, 500);
        if (activeItem.previousElementSibling) {
          activeItem.previousElementSibling.classList.add('prev');
          activeItem.previousElementSibling.classList.add('right');
          setTimeout(() => {
            activeItem.previousElementSibling.classList.remove('prev');
            activeItem.previousElementSibling.classList.remove('right');
            activeItem.previousElementSibling.classList.add('active');
          }, 500);
        } else {
          lastItem.classList.add('prev');
          lastItem.classList.add('right');
          setTimeout(() => {
            lastItem.classList.remove('prev');
            lastItem.classList.remove('right');
            lastItem.classList.add('active');
          }, 500);
        }
      });
    }
    if (nextArrow) {
      nextArrow.addEventListener('click', () => {
        const activeItem = document.querySelector(
          '#myCarousel .carousel-inner .carousel-item.active'
        );
        activeItem.classList.add('left');
        setTimeout(() => {
          activeItem.classList.remove('active');
          activeItem.classList.remove('left');
        }, 500);
        if (activeItem.nextElementSibling) {
          activeItem.nextElementSibling.classList.add('next');
          activeItem.nextElementSibling.classList.add('left');
          setTimeout(() => {
            activeItem.nextElementSibling.classList.remove('next');
            activeItem.nextElementSibling.classList.remove('left');
            activeItem.nextElementSibling.classList.add('active');
          }, 500);
        } else {
          firstItem.classList.add('next');
          firstItem.classList.add('left');
          setTimeout(() => {
            firstItem.classList.remove('next');
            firstItem.classList.remove('left');
            firstItem.classList.add('active');
          }, 500);
        }
      });
    }
  }

  setPageTitle() {
    if (this.isFeederCampaignPage) {
      this.titleService.setTitle(
        'Franklin India Feeder - Franklin U.S. Opportunities Fund - Invest Now with Franklin Templeton India'
      );
    }
    if (this.isBlueChipCampignPage) {
      this.titleService.setTitle(
        'Franklin India Bluechip Fund - Invest Now with Franklin Templeton India'
      );
    }
  }

  resizeImage(screenWidth) {
    const bannerElement = document.querySelector<HTMLImageElement>(
      '#banner-img'
    );
    if (bannerElement) {
      if (screenWidth >= 1024) {
        if (this.isFeederCampaignPage) {
          bannerElement.src = this.translateService.instant(
            'ftiCommon.usBannerImgForLargeScreen'
          );
        }
        if (this.isElssCampaignPage) {
          bannerElement.src = this.translateService.instant(
            'ftiCommon.elssFundBannerImgForLargeScreen'
          );
        }
        if (this.isBlueChipCampignPage) {
          bannerElement.src = this.translateService.instant(
            'ftiCommon.blueChipBannerImgForLargeScreen'
          );
        }
        if (this.isPrimaFundPageCampignPage) {
          bannerElement.src = this.translateService.instant(
            'ftiCommon.primaBannerImgForLargeScreen'
          );
        }
      }
      if (screenWidth <= 480 && screenWidth > 1024) {
        if (this.isFeederCampaignPage) {
          bannerElement.src = this.translateService.instant(
            'ftiCommon.usBannerImgForMediumScreen'
          );
        }
        if (this.isElssCampaignPage) {
          bannerElement.src = this.translateService.instant(
            'ftiCommon.elssFundBannerImgForMediumScreen'
          );
        }
        if (this.isBlueChipCampignPage) {
          bannerElement.src = this.translateService.instant(
            'ftiCommon.blueChipBannerImgForMediumScreen'
          );
        }
        if (this.isPrimaFundPageCampignPage) {
          bannerElement.src = this.translateService.instant(
            'ftiCommon.primaBannerImgForMediumScreen'
          );
        }
      }
    }
  }

  /**
   * On component destroyed
   * Removes any remaining subscriptions
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <style>
      .modal .close {
        color: #dbeffb;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: -20px;
        font-size: 1.875rem;
      }
      .modal .modal-content {
        background-color: #fefefe;
        border: 1px solid #888;
        display: inline-block;
        left: 50%;
        margin: 0 auto;
        max-width: 90%;
        padding: 20px;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .modal .modal-inner {
        height: 6000px;
        max-height: 600px;
        max-width: 1200px;
        position: relative;
      }
    </style>
    <div id="modal-wrapper" [innerHTML]="name"></div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class NgbdModalContentComponent {
  @Input() name;

  constructor(public _NgbActiveModal: NgbActiveModal) {
  }
}
