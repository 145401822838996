<ng-container [brManageMenuButton]="menuModel"></ng-container>
<header class="header fixed-top" id="mainHeader"
        [ngClass]="page?.isPreview()? 'has-edit-button-head' :'z-index-header'">
  <div class="header-sticky">
    <div class="row p-2 header-notice" *ngIf="noticeBannerIsOpened">
      <div class="text-white notice-content">
        <span [innerHTML]="ftiOutageMessageTitle"></span>
        <div class="row subcontent">
          <div class="col-lg-9 col-sm-9" [innerHTML]="ftiOutageMessageValue"></div>
          <div class="col-lg-3 col-sm-3 text-end align-items-center close-button" *ngIf="!optionalsBannerClose">
            <button type="button"
                    class="btn btn-outline-primary bg-light" style="width:140px;"
                    (click)="noticeBannerToggle()">{{ ftiOutageMessageCloseValue }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- start top menu block desktop-->
    <div class="top-menu-block">
      <div class="container-fluid ps-0">
        <div class="header-menu-btn">
          <ul class="user-type">
            <div class="d-flex gap-3">
              <li [ngClass]="{ 'active': segmentSelected !=='Distributor' }"
                  (click)="handleSegmentChange('')">
                <a data-id="inv" [routerLink]="['/']">Investor</a>
              </li>
              <li (click)="handleSegmentChange('Distributor')"
                  [ngClass]="{ 'active': segmentSelected ==='Distributor' }">
                <a [routerLink]="['/distributor']">Distributor</a>
              </li>
            </div>
          </ul>
        </div>
        <ul class="links">
          <li *ngIf="siteToggle" style="padding: 5px;">
            <div class="d-flex newUi align-items-center tog-header">
              <ng-container *ngIf="midLaptop else deskTop">
                <div id="blink"></div>
                <a class="site-toggle cur-pointer" style="color: var(--blue);"
                   href="https://pre.franklintempletonindia.com">Visit old site</a>
              </ng-container>
              <ng-template #deskTop>
                <span class="tog-new"><span>New</span></span>
                <div id="blink1" class="blink1"></div>
                <span class="mr-2" [innerHTML]="siteToggleVal"> </span>
              </ng-template>
            </div>
          </li>
          <!-- Dark mode slider  starts-->
          <ng-container *ngIf="!isMidScreen">
            <li style="padding: 0 5px 0 15px;">
              <div class="dark-mode-wrap">
                <div>
                  <img alt="Theme light" class="light-active" src="assets/images/general/mode_sun_active.svg"
                       (click)="handleThemeModeClick('light')">
                  <img alt="Theme light" class="light-inactive d-none" src="assets/images/general/mode_sun.svg"
                       (click)="handleThemeModeClick('light')">
                </div>
                <div>
                  <img alt="Theme Dark" class="dark-inactive" src="assets/images/general/mode_moon.svg"
                       (click)="handleThemeModeClick('dark')">
                  <img alt="Theme Dark" class="dark-active d-none" src="assets/images/general/mode_moon_active.svg"
                       (click)="handleThemeModeClick('dark')">
                </div>
              </div>
            </li>
            <li style="padding: 8px 10px;">
              <div class="font-resize-wraper">
                <button [ngClass]="{ 'activeResize': activeFontSize == 1 }" (click)="fontResize(1)">A-</button>
                <button [ngClass]="{ 'activeResize': activeFontSize == 2 }" (click)="fontResize(2)">A</button>
                <button [ngClass]="{ 'activeResize': activeFontSize == 3 }" (click)="fontResize(3)">A+</button>
              </div>
            </li>

            <li *ngFor="let topMenus of topMenuList; let i = index">
              <div class="dropdown" *ngIf="topMenus?.childMenuItems.length > 0; else noChild">
                <button
                    [id]="topMenus.name"
                    type="button"
                    class="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    (mouseover)="topMenuShowOnHover($event)"
                    (click)="setAnalytics(topMenus)">
                  {{ topMenus.name }}
                </button>
                <ul class="dropdown-menu" (mouseleave)="contentOnHoverClose(topMenus.name)">
                  <li *ngFor=" let childMenus of topMenus.childMenuItems; let i = index"
                      (click)="setAnalyticsTopSubMenu(topMenus,childMenus)">
                    <ng-container
                        [ngTemplateOutlet]="childLinks"
                        [ngTemplateOutletContext]="
                        { childItem: childMenus,
                         class: 'dropdown-item',
                         iconRight:false,
                         descText:false}">
                    </ng-container>
                  </li>
                </ul>
              </div>
              <ng-template #noChild>
                <div (click)="setAnalytics(topMenus)">
                  <ng-container
                      [ngTemplateOutlet]="childLinks"
                      [ngTemplateOutletContext]="
                        { childItem: topMenus,
                         class: 'otherlinks',
                         iconRight:false,
                         descText:false}">
                  </ng-container>
                </div>
              </ng-template>
            </li>
            <li>
              <div class="dropdown">
                <button class="btn btn-white dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false" (click)="setAnalytics({name:'Country'})">
                  <img alt="India" src="assets/images/general/india-flag.svg"/>
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <a class="dropdown-item">
                      <img alt="India"
                           src="assets/images/general/india-flag.svg"/> {{ "ftiGeneric.countryMenuLabel" | translate }}
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" target="{{ 'ftiGeneric.countryMenuChooseTarget' | translate }}"
                       [href]="getFtCountryUrl">
                      <button class="btn btn-primary"
                              (click)="setAnalyticsTopSubMenu({name:'Country'},{name:'CHOOSE COUNTRY'})">
                        {{ "ftiGeneric.countryMenuChoose" | translate }}
                      </button>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>

    <!-- start main menu block mobile-->
    <div class="top-menu-block d-block d-md-none" style="padding: 3px 0 5px 0;">
      <div class="container-fluid ps-0">
        <div class="header-menu-btn">
          <ul class="user-type">
            <div class="d-flex gap-3">
              <li [ngClass]="{ 'active': segmentSelected !=='Distributor' }"
                  (click)="handleSegmentChange('')">
                <a data-id="inv" [routerLink]="['/']">Investor</a>
              </li>
              <li (click)="handleSegmentChange('Distributor')"
                  [ngClass]="{ 'active': segmentSelected ==='Distributor' }">
                <a [routerLink]="['/distributor']">Distributor</a>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>

    <nav class="navbar navbar-expand-lg bg-primary-gradient">
      <div class="container-fluid">
        <a class="navbar-brand logo-click" [routerLink]="['/']">
          <img alt="Franklin India" src="assets/images/general/header-logo.webp" class="light-logo"/>
          <img alt="Franklin India" src="assets/images/general/footer-logo.svg" class="dark-logo"/>
        </a>
        <div class="small-menu-icons d-inline-flex d-lg-none">
          <ul class="navbar-nav ">
            <li class="nav-item" (click)="toggleShowSearch()">
              <img alt="Site Search" src="assets/images/general/search-white.svg">
            </li>
            <ng-container *ngIf="isLoggedIn else showLogin">
              <li class="nav-item">
                <a class="nav-link" (click)="myAccounts()">
                  <img alt="My Account" style="width:23px" src="assets/images/general/my-accounts.png"></a>
              </li>
              <li class="nav-item">
                <a class="nav-link btn-outline" (click)="signOut()">Logout</a>
              </li>
            </ng-container>
            <ng-template #showLogin>
              <li class="nav-item">
                <a class="nav-link btn-outline cur-pointer" href="javascript:void(0)"
                   (click)="loginGA()">{{ btnLoginText }}</a>
              </li>
            </ng-template>
            <li class="nav-item">
              <button class="navbar-toggler" (click)="toggleShowSideMenu()">
                <img alt="Expand Menu" src="assets/images/general/menu-icon.svg">
              </button>
            </li>
          </ul>
        </div>
        <div class="collapse navbar-collapse">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <ng-container *ngFor="let mainMenu of menuList; let menuIndex = index">
              <ng-container
                  *ngIf="segmentSelected ==='Distributor' && !mainMenu.parameters['Request parameter (key=value)'].includes( 'appInvestor')"
                  [ngTemplateOutlet]="mainMenuCode">
              </ng-container>
              <ng-container
                  *ngIf="segmentSelected !=='Distributor' && !mainMenu.parameters['Request parameter (key=value)'].includes( 'appAdvisor')"
                  [ngTemplateOutlet]="mainMenuCode">
              </ng-container>
              <ng-template #mainMenuCode>
                <li class="nav-item dropdown fill-width">
                  <a class="nav-link dropdown-toggle top-navigation" href="#" [id]="getMenuID(mainMenu.name, false)"
                     role="button"
                     data-bs-toggle="dropdown" aria-expanded="false">
                    {{ mainMenu.name }}
                  </a>
                  <div class="dropdown-menu" (click)="$event.stopPropagation()"
                       (mouseleave)="contentOnHoverClose(getMenuID(mainMenu.name,false))">
                    <div class="row">
                      <div class="col-auto">
                        <div class="menu-tabs">
                          <h3>{{ mainMenu.name }} </h3>
                          <div class="mb-5">
                            <span [innerHtml]="getMenuItemShortDescription(mainMenu)"></span>
                          </div>
                          <div
                              class="nav flex-column nav-pills"
                              id="v-pills-tab"
                              role="tablist"
                              aria-orientation="vertical"
                          >
                            <button
                                *ngFor="let secondLevel of mainMenu?.childMenuItems; let i = index"
                                class="nav-link checkFocus"
                                [ngClass]="{ active: i === 0 }"
                                [id]="'v-pills-'+getMenuID(secondLevel.name, true) +'-button'"
                                data-bs-toggle="pill"
                                [attr.data-bs-target]="'#v-pills-'+getMenuID(secondLevel.name, true)"
                                type="button"
                                role="tab"
                                [attr.data-tab-index]="'custom-headersidepan'+ menuIndex"
                                [attr.data-tab-id]="'v-pills-'+getMenuID(secondLevel.name, true)"
                                [attr.data-click-id]="getMenuID(mainMenu.name, true)+'-landing'"
                                [attr.data-land-btn-id]="getMenuID(secondLevel.name, true)+'-landing'"
                                [attr.aria-controls]="'v-pills-'+getMenuID(secondLevel.name, true)"
                                [attr.aria-selected]="{ true: i === 0 }"
                                (click)="setAnalyticsTopSubMenu(mainMenu,secondLevel)"
                                (mouseover)="showTabContentOnHover($event)">
                              {{ secondLevel.name }}
                              <i class="bi bi-chevron-right"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="tab-content custom-headersidepan{{menuIndex}}"
                             id="v-pills-tabContent{{menuIndex}}">
                          <div
                              *ngFor="let secondLevel of mainMenu?.childMenuItems; let i = index, let l = count"
                              class="tab-pane fade"
                              [ngClass]="{'show active keep-this-active': i===0}"
                              [id]="'v-pills-'+getMenuID(secondLevel.name, true)"
                              role="tabpanel"
                              [attr.aria-labelledby]="'v-pills-'+getMenuID(secondLevel.name, true) +'-tab'"
                              [tabindex]="i">
                            <div class="row menu-options-wraps">
                              <ng-container
                                  *ngFor="let thirdLevel of secondLevel?.childMenuItems; let first = first, let l = count">
                                <ng-container *ngIf="(thirdLevel.parameters[
                              'Request parameter (key=value)'
                            ] !== 'app=mainLandingPage')">
                                  <div class="col-4">
                                    <ng-container
                                        *ngIf="!(thirdLevel.parameters['Request parameter (key=value)'] | IsDataTarget)">
                                      <div class="link-box"
                                           (click)="desktopThirdLevel(thirdLevel);setAnalyticsTopSubMenu(secondLevel,thirdLevel)">
                                        <ng-container
                                            [ngTemplateOutlet]="childLinks"
                                            [ngTemplateOutletContext]="{ childItem: thirdLevel,
                                            class: '',
                                            iconRight:true,
                                            desktopThirdLevel:true,
                                            descText:true}">
                                        </ng-container>
                                      </div>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="thirdLevel.parameters['Request parameter (key=value)'] | IsDataTarget">
                                      <div class="link-box" data-bs-toggle="modal"
                                           [attr.data-bs-target]="thirdLevel.parameters['Request parameter (key=value)'] | DataTargetValue">
                                        <a (click)="setAnalyticsTopSubMenu(secondLevel,thirdLevel)">
                                          {{ thirdLevel.name }}
                                          <i class="bi bi-chevron-right"></i>
                                        </a>
                                        <div class="mega-menu-desc"
                                             (click)="setAnalyticsTopSubMenu(secondLevel,thirdLevel)">
                                          <span [innerHtml]="getMenuItemShortDescription(thirdLevel)"></span>
                                        </div>
                                      </div>
                                    </ng-container>
                                  </div>
                                </ng-container>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                        <ng-container
                            *ngFor="let secondLevel of mainMenu?.childMenuItems; let level2Index = index, let l = count">
                          <ng-container
                              *ngFor="let thirdLevel of secondLevel?.childMenuItems; let first = first">
                            <ng-container *ngIf="(thirdLevel.parameters[
                                'Request parameter (key=value)'
                              ] === 'app=mainLandingPage')">
                              <ng-container
                                  [ngTemplateOutlet]="childLinks"
                                  [ngTemplateOutletContext]="
                                        { childItem: thirdLevel,
                                        class: getButtonClass(mainMenu.name),
                                        iconRight:true,
                                        hidden:true,
                                        index: level2Index,
                                        hiddenBtnName:getMenuID(secondLevel.name+'-landing', true),
                                        descText:false}">
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </li>
              </ng-template>
            </ng-container>
            <li class="nav-item form-item">
              <form>
                <div class="small-menu-icons d-inline-flex">
                  <ul class="navbar-nav " style="cursor: pointer;
                                    position: relative;
                                    left: 4px;
                                    bottom: 2px;">
                    <li class="nav-item cart-icon" data-bs-toggle="modal"
                        data-bs-target="#searchModal" (click)="onOpenSearch()">
                      <img alt='image' src="assets/images/general/search-white.svg">
                    </li>
                  </ul>
                </div>
              </form>
            </li>
            <li class="nav-item dropdown checkout-btn cart-icon"></li>
            <ng-container *ngIf="isLoggedIn else showLogin">
              <li class="nav-item" style="margin: 0 10px;">
                <a class="nav-link btn-outline cur-pointer" (click)="myAccounts()">
                  My Account
                </a>
              </li>
              <li class="nav-item" style="background: var(--blue);">
                <a class="nav-link btn-outline cur-pointer" (click)="signOut()">Logout</a>
              </li>
            </ng-container>
            <ng-template #showLogin>
              <li class="nav-item">
                <a class="nav-link btn-outline cur-pointer" href="javascript:void(0)"
                   (click)="loginGA()">{{ btnLoginText }}</a>
              </li>
            </ng-template>
          </ul>
        </div>
      </div>
    </nav>
  </div>

  <!-- small screens dropdowns -->
  <div class="d-flex d-md-none more-links-btns">
    <div class="accordion row g-0" id="more-links-btns">
      <ng-container *ngFor="let mMenu of mobileDDmenu; let i = index">
        <ng-container
            *ngIf="segmentSelected ==='Distributor' && !mMenu.parameters['Request parameter (key=value)'].includes( 'appInvestor')"
            [ngTemplateOutlet]="ddCode">
        </ng-container>
        <ng-container
            *ngIf="segmentSelected !=='Distributor' && !mMenu.parameters['Request parameter (key=value)'].includes( 'appAdvisor')"
            [ngTemplateOutlet]="ddCode">
        </ng-container>
        <ng-template #ddCode>
          <div class="accordion-item col-6">
            <ng-container *ngIf="i===0">
              <h2 class="accordion-header" id="accordion{{i}}">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#' + getMenuID(mMenu.name, false)" aria-expanded="false"
                        [attr.aria-controls]="getMenuID(mMenu.name, false)" (click)="setAnalytics(mMenu)">
                  {{ mMenu.name }}
                </button>
              </h2>
              <div [id]="getMenuID(mMenu.name, false)" class="accordion-collapse collapse"
                   [attr.aria-labelledby]="'accordion'+i"
                   data-bs-parent="#more-links-btns">
                <div class="accordion-body">
                  <ng-container *ngFor="let item of mMenu?.childMenuItems; let j = index">
                    <ng-container
                        [ngTemplateOutlet]="childLinks"
                        [ngTemplateOutletContext]="{ childItem: item,
                          secondLevel:mMenu,
                  class: 'link-toggle', iconRight:true }">
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="i!==0">
              <h2 class="accordion-header" id="accordion{{i}}">
                <button id="investor-service-main" class="accordion-button collapsed" type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#' + getMenuID(mMenu.name, false)" aria-expanded="false"
                        [attr.aria-controls]="getMenuID(mMenu.name, false)" (click)="setAnalytics(mMenu)">
                  {{ mMenu.name }}
                </button>
              </h2>
              <div [id]="getMenuID(mMenu.name, false)" class="accordion-collapse collapse"
                   [attr.aria-labelledby]="'accordion'+i"
                   data-bs-parent="#more-links-btns">
                <div class="accordion-body">
                  <ng-container *ngFor="let secondlevel of mMenu?.childMenuItems; let j = index">
                    <div class="dropdown"
                         [hidden]="activeMobileHeaderId !== (getMenuID(secondlevel.name, false)) && activeMobileHeaderId!==''">
                      <button class="link-toggle dropdown-toggle" type="button" data-bs-toggle="dropdown"
                              aria-expanded="false"
                              (click)="toggleMobileHeader(getMenuID(secondlevel.name, false));setAnalyticsTopSubMenu(mMenu,secondlevel)">
                        {{ secondlevel.name }}<i class="bi bi-chevron-right"></i>
                      </button>
                      <div class="dropdown-menu">
                        <div class="flex-block">
                          <div class="block-head" (click)="toggleMobileHeader('')"><i class="bi bi-chevron-left"></i>Back
                          </div>
                          <ul (click)="$event.stopPropagation()">
                            <li *ngFor="let item of secondlevel?.childMenuItems; let k = index">
                              <ng-container
                                  [ngTemplateOutlet]="childLinks"
                                  [ngTemplateOutletContext]="{ childItem: item,
                                    class: 'dropdown-item',
                                    secondLevel:secondlevel,
                                    iconRight:false, descText:false, isMobileMenu:true}">
                              </ng-container>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </div>
  <div class="progress-container">
    <div class="progress-bar" id="myBar"></div>
  </div>
</header>
<!-- NOTE: Header top spacing handle, this must be just after the header tag, can't be moved or removed -->
<div id="handle-top-spacing"
     class="FT-top-spacing {{isMobileView && !noticeBannerIsOpened ? 'FT-default-top-mob':''}} {{!isMobileView && !noticeBannerIsOpened ? 'FT-default-top-web':''}}">
</div>
<!-- small search block -->
<div class="sm-search-block" *ngIf="ShowSearch" id="closePage">
  <div class="block-head row">
    <button class="btn btn-transparent col-auto" (click)="toggleShowSearch()">
      <i class="bi bi-arrow-left"></i>
    </button>
    <div class="col">
      <div class="form-group">
        <input type="text" aria-label="Search" autocomplete="off" class="form-control"
               placeholder="{{'ftiGeneric.search-placeholder' | translate }}" [(ngModel)]="searchText"
               (click)="onSearchInputClick()" (keyup)="onSearchKeyUp()"/>
        <div class="search-result" *ngIf="showAutoSuggestions">
          <div class="inner" style="position: relative;left: 11px;top: 32px;">
            <ft-auto-suggestions-list [searchText]="searchText"
                                      [triggerChange]="triggerChange"></ft-auto-suggestions-list>
          </div>
        </div>

        <button class="btn btn-transparent" (click)="toggleShowSuggest()">
          <img alt="site search" src="assets/images/general/search-icon.svg"/>
        </button>
      </div>
    </div>

    <button type="button" (click)="navigateToSearchResults()" style="padding: 8px 15px;"
            class="btn btn-primary btn-sm col-auto">{{ 'ftiGeneric.search-go-mobile-btn' | translate }}
    </button>
  </div>
  <div class="recent-search">
    <div class="text-bold">{{ 'ftiGeneric.search-heading2' | translate }}</div>
    <ng-container *ngFor="let recentSearch of recentSearches">
      <a class="d-flex justify-content-between align-items-center"
         (click)="navigateToSearchResults(recentSearch?.term)">
        <span>{{ recentSearch.term }}</span>
        <i class="bi bi-arrow-up-right"></i>
      </a>
    </ng-container>
  </div>
  <div class="recent-search">
    <div class="text-bold">{{ 'ftiGeneric.search-heading1' | translate }}</div>
    <ng-container *ngFor="let suggestion of suggestions">
      <a class="d-flex justify-content-between align-items-center"
         (click)="navigateToSearchResults(suggestion?.value)">
        <span>{{ suggestion?.value }}</span>
        <i class="bi bi-arrow-up-right"></i>
      </a>
    </ng-container>
  </div>

</div>

<!-- small screen hamburger block -->
<div class="d-lg-none" *ngIf="ShowSideMenu">
  <div class="page-overlay"></div>
  <div class="FT-small-menu" [class.opened]="ShowSideMenu === true">
    <div class="main-menu-block">
      <div class="px-3">
        <div class="theme-options mob-resize-wraper mb-4" style="justify-content:right;">
          <div style="padding: 8px 10px;">
            <div class="font-resize-wraper">
              <button [ngClass]="{ 'activeResize': activeFontSize == 1 }" (click)="fontResize(1)">A-</button>
              <button [ngClass]="{ 'activeResize': activeFontSize == 2 }" (click)="fontResize(2)">A</button>
              <button [ngClass]="{ 'activeResize': activeFontSize == 3 }" (click)="fontResize(3)">A+</button>
            </div>
          </div>
          <div style="padding: 0 5px 0 15px;">
            <div class="dark-mode-wrap">
              <div>
                <img alt="Theme Change" class="light-active" src="assets/images/general/mode_sun_active.svg"
                     (click)="handleThemeModeClick('light')">
                <img alt="Theme Change" class="light-inactive d-none" src="assets/images/general/mode_sun.svg"
                     (click)="handleThemeModeClick('light')">
              </div>
              <div>
                <img alt="Theme Change" class="dark-inactive" src="assets/images/general/mode_moon.svg"
                     (click)="handleThemeModeClick('dark')">
                <img alt="Theme Change" class="dark-active d-none" src="assets/images/general/mode_moon_active.svg"
                     (click)="handleThemeModeClick('dark')">
              </div>
            </div>
          </div>
        </div>
        <div class="menu">
          <div class="accordion" id="menuAccordion">
            <ng-container *ngFor="let menuListItems of menuList; let i = index">
              <ng-container
                  *ngIf="segmentSelected ==='Distributor' && !menuListItems.parameters['Request parameter (key=value)'].includes( 'appInvestor')"
                  [ngTemplateOutlet]="hamburgerCode">
              </ng-container>
              <ng-container
                  *ngIf="segmentSelected !=='Distributor' && !menuListItems.parameters['Request parameter (key=value)'].includes( 'appAdvisor')"
                  [ngTemplateOutlet]="hamburgerCode">
              </ng-container>
              <ng-template #hamburgerCode>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="heading {{i}}">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#item' +i"
                        aria-expanded="true"
                        [attr.aria-controls]="'item'+i"
                        (click)="setAnalytics(menuListItems)">
                      {{ menuListItems.name }}
                    </button>
                  </h2>
                  <div id="item{{i}}" class="accordion-collapse collapse"
                       [attr.aria-labelledby]="'heading'+i"
                       data-bs-parent="#menuAccordion">
                    <div class="accordion-body">
                      <div class="dropdown" *ngFor="let child of menuListItems?.childMenuItems; let i = index">
                        <button
                            class="btn btn-light-blue dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            (click)="getSubChildForSmallMenu(child);setAnalyticsTopSubMenu(menuListItems,child)">
                          {{ child.name }}
                        </button>
                        <div class="dropdown-menu">
                          <div class="flex-block">
                            <div>
                              <button
                                  class="btn btn-light-blue dropdown-toggle"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false">
                                <i class="bi bi-arrow-left"></i>
                              </button>
                              <ul>
                                <ng-container *ngFor="let menuListChildItems of subChildMenuList;let j = index">
                                  <!-- I commented below the code since the View-All options must be displayed according to the design.-->
                                  <!--*ngIf="(menuListChildItems.parameters[ 'Request parameter (key=value)'] !== 'app=mainLandingPage')"-->
                                  <li (click)="setAnalyticsTopSubMenu(child,menuListChildItems)">
                                    <ng-container
                                        [ngTemplateOutlet]="childLinks"
                                        [ngTemplateOutletContext]="{ childItem: menuListChildItems,
                                          class: 'dropdown-item', iconRight:false, isMobileMenu:true }">
                                    </ng-container>
                                  </li>
                                </ng-container>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </div>
        </div>
        <div style="padding: 8px 1px;" *ngIf="siteToggle">
          <div class="font-resize-wraper tog-header">
            <div class="d-flex newUi align-items-center">
              <span class="tog-new"><span>New</span></span><span class="mr-2 mobile-tog"
                                                                 [innerHTML]="siteToggleVal"> </span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="block-footer">
          <ul>
            <li *ngFor="let topMenus of topMenuList; let i = index">
              <a *ngIf="topMenus?.links?.site?.href" [href]="topMenus.links.site.href"
                 [type]="topMenus.links.site.type" (click)="setAnalytics(topMenus)">{{ topMenus.name }}</a>
              <a *ngIf="!topMenus?.links?.site" [href]="topMenus.name" (click)="setAnalytics(topMenus)">
                {{ topMenus.name }}</a>
            </li>
          </ul>
        </div>
        <div class="selected-country px-3">
          <span>{{ "ftiGeneric.select-country-label" | translate }}</span>
          <div class="dropdown">
            <button (click)="countryUrl(getFtCountryUrl);setAnalytics({name:'Country'})"
                    class="btn btn-light-blue dropdown-toggle" type="button">
              {{ "ftiGeneric.countryMenuLabel" | translate }}
              <img alt="India" src="assets/images/flags/flag1.png"/>
            </button>
          </div>
        </div>

        <button
            class="btn btn-transparent close-menu"
            (click)="toggleShowSideMenu()">
          <i class="bi bi-x-lg"></i>
          Close
        </button>
      </div>
    </div>
  </div>
</div>


<!--Template To handle Anchor events across header-->
<ng-template #childLinks
             let-childItem="childItem"
             let-secondLevel="secondLevel"
             let-class="class"
             let-iconRight='iconRight'
             let-hidden='hidden'
             let-index='index'
             let-hiddenBtnName='hiddenBtnName'
             let-descText='descText'
             let-desktopThirdLevel='desktopThirdLevel'
             let-isMobileMenu='isMobileMenu'>

  <ng-container *ngIf="childItem">
    <ng-container *ngIf="!desktopThirdLevel else desktopThirdLevelLink">
      <a
          *ngIf="!childItem.links.site"
          [class]="hidden && index!==0? class +' hide-this': class"
          [id]="hidden? hiddenBtnName:''"
          (click)="setAnalyticsTopSubMenu(secondLevel,childItem)">
        {{ childItem.name }}
        <i *ngIf="iconRight" class="bi bi-chevron-right"></i>
      </a>
      <a
          *ngIf="!(childItem.parameters['Request parameter (key=value)'] | IsDataTarget) &&childItem.links?.site | isExternalLink"
          [href]="getUrl(childItem)"
          [class]="hidden && index!==0? class +' hide-this': class"
          [id]="hidden? hiddenBtnName:''"
          [target]="childItem | isNewPage"
          (click)="setAnalyticsTopSubMenu(secondLevel,childItem)">
        {{ childItem.name }}
        <i *ngIf="iconRight" class="bi bi-chevron-right"></i>
      </a>
      <ng-container *ngIf="childItem.parameters['Request parameter (key=value)'] | IsDataTarget">
        <a *ngIf="!isMobileMenu else mobileOffCanvas" [class]="hidden && index!==0? class +' hide-this': class"
           data-bs-toggle="modal"
           [attr.data-bs-target]="childItem.parameters['Request parameter (key=value)'] | DataTargetValue"
           href="#">
          {{ childItem.name }}
          <i *ngIf="iconRight" class="bi bi-chevron-right"></i>
        </a>
        <ng-template #mobileOffCanvas>
          <a *ngIf="isMobileMenu" [class]="hidden && index!==0? class +' hide-this': class"
             data-bs-toggle="offcanvas" aria-controls="offcanvasBottom"
             [attr.data-bs-target]="childItem.parameters['Request parameter (key=value)'] | DataTargetVal"
             href="#"
             (click)="onCloseSidePannel();setAnalyticsTopSubMenu(secondLevel ? secondLevel: {name:'Ways to transact with us'},childItem)">
            {{ childItem.name }}
            <i *ngIf="iconRight" class="bi bi-chevron-right"></i>
          </a>
        </ng-template>
      </ng-container>
      <a *ngIf="(childItem.parameters['Request parameter (key=value)'] === 'app=externalUrl' && childItem.parameters['Request parameter (key=value)'] !== 'app=redirectGuestUrl')
        || childItem.links?.site | isInternalLink"
         [target]="childItem | isNewPage"
         [class]="hidden && index!==0? class +' hide-this': class"
         [id]="hidden? hiddenBtnName:''"
         [routerLink]="page?.getUrl(childItem.links?.site?.href)"
         (click)="setAnalyticsTopSubMenu(secondLevel,childItem)">
        {{ childItem.name }}
        <i *ngIf="iconRight" class="bi bi-chevron-right"></i>
      </a>
    </ng-container>
    <ng-template #desktopThirdLevelLink>
      <a>
        {{ childItem.name }}
        <i *ngIf="iconRight" class="bi bi-chevron-right"></i>
      </a>
    </ng-template>
    <div class="mega-menu-desc" *ngIf="descText">
      <span [innerHtml]="getMenuItemShortDescription(childItem)"></span>
    </div>
  </ng-container>
</ng-template>

<!-- search model popup -->
<div *ngIf="showModal" class="modal fade" id="searchModal" tabindex="-1" aria-labelledby="searchModalLabel"
     aria-hidden="true" style="z-index: 99999;">
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content" id="search-content">
      <div class="text-end m-4 d-flex" style="justify-content: end; flex-direction: row-reverse;">
        <button type="button" class="btn-close" data-bs-dismiss="modal" id="modalClosebtn" aria-label="Close"></button>
        <div>
          <span class="mx-3" data-bs-dismiss="modal" style="cursor: pointer;"><small>Close</small></span>
        </div>
      </div>
      <div class="modal-body">
        <ft-search-data (close)="showModal = false"></ft-search-data>
      </div>
    </div>
  </div>
</div>

<!-- email us model popup -->
<div class="modal fade" id="emailModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
     style="z-index: 1000000;">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <div class="text-center w-100">
          <h5 class="modal-title">{{ "ftiGeneric.email-us" | translate }}</h5>
        </div>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h6 class="text-center mb-4">
          To:<a href="javascript:void(0);">{{ "ftiGeneric.investors-email" | translate }}</a>
        </h6>
        <ft-email-us-form></ft-email-us-form>
      </div>
    </div>
  </div>
</div>

<div class="offcanvas offcanvas-bottom sort-bottom" tabindex="-1" id="offcanvasemailModal"
     aria-labelledby="offcanvasBottomLabel"
     style="height: 55%; border-top-left-radius: 39px; border-top-right-radius: 39px;">
  <div class="offcanvas-header">
    <div class="line"></div>
    <div class="d-flex justify-content-between mt-4 w-100" *ngIf="!getAlert">
      <div>
        <div class="modal-title custom-modal-title" id="callBackLabel">{{ "ftiGeneric.email-us" | translate }}</div>
        <h6 class="text-center mb-4 mt-2">
          To:<a href="javascript:void(0);">{{ "ftiGeneric.investors-email" | translate }}</a>
        </h6>
        <div class="mt-1 modal-subtitile">{{ "ftiGeneric.email-us-form-enter" | translate }}</div>
      </div>
      <div class="text-end">
        <div type="button" class="text-reset" data-bs-dismiss="offcanvas" aria-label="Close"><img alt='image'
                                                                                                  src="assets/images/general/Frame1000009207.svg">
        </div>
      </div>
    </div>
  </div>
  <div class="offcanvas-body small">
    <ft-email-us-form [isSmall]="true"></ft-email-us-form>
  </div>
</div>

<!-- speak to us model popup desktop view-->
<div class="modal fade" id="speakModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
     style="z-index: 1000000;">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <div class="text-center w-100">
          <h5 class="modal-title">{{ 'ftiGeneric.speak-to-us' | translate }}</h5>
        </div>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <ft-speak-to-us-data></ft-speak-to-us-data>
      </div>
      <div class="modal-footer">
        <div></div>
      </div>
    </div>
  </div>
</div>
<!-- speak to us model popup mobile view-->
<div class="offcanvas offcanvas-bottom sort-bottom" tabindex="-1" id="offcanvasspeakModal"
     aria-labelledby="exampleModalLabel"
     style="height: 55%; border-top-left-radius: 39px; border-top-right-radius: 39px;">
  <div class="offcanvas-header">
    <div class="line"></div>
    <div class="d-flex justify-content-between mt-4 w-100">
      <div>
        <div class="modal-title custom-modal-title">{{ 'ftiGeneric.speak-to-us' | translate }}</div>
      </div>
      <div class="text-end">
        <div type="button" class="text-reset" data-bs-dismiss="offcanvas" aria-label="Close"><img alt='image'
                                                                                                  src="assets/images/general/Frame1000009207.svg">
        </div>
      </div>
    </div>
  </div>
  <div class="offcanvas-body small">
    <ft-speak-to-us-data></ft-speak-to-us-data>
  </div>
</div>
<!-- Request a callback Modal desktop -->
<div class="modal fade" id="callBackRequest" tabindex="-1" aria-labelledby="callBackLabel" aria-hidden="true"
     style="z-index: 1000000;">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <ft-call-back-form [classVal]="'contact'"></ft-call-back-form>
    </div>
  </div>
</div>

<!-- Request a callback Modal Mobile -->
<div class="offcanvas offcanvas-bottom sort-bottom" tabindex="-1" id="offcanvascallBackRequest"
     aria-labelledby="offcanvasBottomLabel"
     style="height: 55%; border-top-left-radius: 39px; border-top-right-radius: 39px;">
  <div class="offcanvas-header">
    <div class="line"></div>
    <div class="d-flex justify-content-between mt-4 w-100">
      <div>
        <div class="modal-title custom-modal-title">{{ "ftiGeneric.lead-form-title" | translate }}</div>
      </div>
      <div class="text-end cross-btn">
        <div type="button" class="text-reset" data-bs-dismiss="offcanvas" aria-label="Close"><img alt='image'
                                                                                                  src="assets/images/general/Frame1000009207.svg">
        </div>
      </div>
    </div>
  </div>
  <div class="offcanvas-body small">
    <div class="modal-body">
      <ft-call-back-form [isSmall]="true" [isCallback]="true"></ft-call-back-form>
    </div>
  </div>
</div>
