<section class="FT-knowledge">
  <div class="container marketing-categories">
    <!-- start categories box -->
    <div class="knowledge-box categories" id="Learning">
      <div class="category-block" id="Blog">
        <div class="d-flex align-items-center justify-content-between">
          <h3>Blogs</h3>
          <!--<a class="knowledge-center-CTA"
             [routerLink]="['/knowledge-center/blogs']">
            <span>{{ "products.focus-card-view-all"| translate }}</span><i class="bi bi-chevron-right"></i>
          </a>-->
        </div>

        <ngx-slick-carousel
            class="carousel"
            #slickModal="slick-carousel"
            [config]="slideConfig"
        >
          <div
              ngxSlickItem
              class="slide"
              *ngFor="let slide of relatedData; let i = index">
            <div class="category-card">
              <div class="card-img" type="button" (click)="blogLinks(slide.target)">
                <img src="{{ slide.imgSrc }}" alt="{{ slide.title }}"/>
              </div>
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between">
                  <span class="time-tag">{{ slide.timeTag }}</span>
                  <!--<div class="card-icons d-flex">
                    <span
                        class="img-with-tooltip me-3 d-flex"
                        style="color: var(&#45;&#45;blue)"
                        (click)="toggleIconForWishlist(i)"
                    >
                    </span>
                    <span
                        class="img-with-tooltip me-3"
                        (click)="toggleIconForWatchlist(i)"
                    >
                      <i
                          [ngStyle]="{
                          display: showIconForWatchlist[i] ? 'inline' : 'none'
                        }"
                          class="bi bi-bookmark"
                      ></i>
                      <i
                          *ngIf="!showIconForWatchlist[i]"
                          class="bi bi-bookmark-fill"
                      ></i>
                      <span
                          [ngStyle]="{
                          display: showIconForWatchlist[i] ? 'inline' : 'none'
                        }"
                          class="tooltip-block"
                      >Save</span
                      >
                      <span
                          *ngIf="!showIconForWatchlist[i]"
                          class="tooltip-block"
                      >Remove from Saved</span
                      >
                    </span>
                    <span class="dropdown">
                      <span
                          class="img-with-tooltip"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                      >
                        <img
                            src="assets/images/knowledge-center/share.svg"
                            (mouseenter)="showTooltip = true"
                            (mouseleave)="showTooltip = false"
                            (click)="toggleTooltip()"
                            class="d-inline-block mobile-icons light-mode-icon"
                            style="width: 18px"
                        />
                        <img
                            src="../../../../../../assets/images/dark/share-dark-icon.png"
                            (mouseenter)="showTooltip = true"
                            (mouseleave)="showTooltip = false"
                            (click)="toggleTooltip()"
                            class="mobile-icons dark-mode-icon"
                            style="
                            width: 21px;
                            top: -1px;
                            position: relative;
                            left: 0px;
                          "
                        />
                        <span *ngIf="showTooltip" class="tooltip-block"
                        >share</span
                        >
                      </span>
                      <ul class="dropdown-menu for-custom-dropdown">
                        <li>
                          <span class="dropdown-item fontResize"
                          ><img
                              class="share-icon"
                              src="assets/images/general/Link.webp"
                          /><span class="action-icon">{{
                                  "products.home-fund-card-share-copy-btn"
                                      | translate
                            }}</span></span
                          >
                        </li>
                        <li>
                          <a class="dropdown-item fontResize" href="#"
                          ><img
                              class="share-icon3"
                              src="assets/images/general/twitter-new-icon.webp"
                              style="width: 20px"
                          /><span class="action-icon">{{
                                  "products.home-fund-card-share-twitter-btn"
                                      | translate
                            }}</span></a
                          >
                        </li>
                        <li>
                          <a class="dropdown-item fontResize" href="#"
                          ><img
                              class="share-icon"
                              src="assets/images/general/FacebookLogo.webp"
                          /><span class="action-icon">{{
                                  "products.home-fund-card-share-fb-btn" | translate
                            }}</span></a
                          >
                        </li>
                        <li>
                          <a class="dropdown-item fontResize" href="#"
                          ><img
                              class="share-icon"
                              src="assets/images/general/LinkedinLogo.webp"
                          /><span class="action-icon">{{
                                  "products.home-fund-card-share-linkedin-btn"
                                      | translate
                            }}</span></a
                          >
                        </li>
                        <li>
                          <a class="dropdown-item fontResize" href="#"
                          ><img
                              class="share-icon"
                              src="assets/images/general/whatsapps.webp"
                          /><span class="action-icon">{{
                                  "products.home-fund-card-share-whatsapp-btn"
                                      | translate
                            }}</span></a
                          >
                        </li>
                      </ul>
                    </span>
                  </div>-->
                </div>
                <div type="button"
                     class="text-bold card-title mt-3 forlikecardtitle">
                  {{ slide.title }}
                </div>
              </div>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>

      <div class="category-block" id="Blog">
        <div class="d-flex align-items-center justify-content-between">
          <h3>Commentaries</h3>
          <a
              class="knowledge-center-CTA"
              [routerLink]="['/knowledge-centre/quick-learn/latest-commentaries']"
          >
            <span>{{ "products.focus-card-view-all"| translate }}</span><i class="bi bi-chevron-right"></i
          ></a>
        </div>

        <ngx-slick-carousel
            class="carousel"
            #slickModal="slick-carousel"
            [config]="slideConfig"
        >
          <div
              ngxSlickItem
              class="slide"
              *ngFor="let slide of getData; let i = index"
          >
            <div class="category-card">
              <!--slide.id % 2 === 1 ? ['/knowledge-center/article1'] : ['/knowledge-center/article2']-->
              <div class="card-img" type="button" (click)="categoryOnClick(slide)" style="cursor: pointer">
                <img alt="image" src="{{ slide.image }}"/>
              </div>
              <div class="card-body">
                <div class="d-flex align-items-center justify-content-between">
                  <span class="time-tag">{{ getFormatedDate(slide?.data?._source?.referenceDate) }}</span>
                  <div class="card-icons d-flex">
                    <span
                        class="img-with-tooltip me-3 d-flex"
                        style="color: var(--blue)"
                        (click)="toggleIconForWishlist(i)"
                    >
                    </span>
                    <span
                        class="img-with-tooltip me-3"
                        (click)="toggleIconForWatchlist(i)"
                    >
                      <i
                          [ngStyle]="{
                          display: showIconForWatchlist[i] ? 'inline' : 'none'
                        }"
                          class="bi bi-bookmark"
                      ></i>
                      <i
                          *ngIf="!showIconForWatchlist[i]"
                          class="bi bi-bookmark-fill"
                      ></i>
                      <span
                          [ngStyle]="{
                          display: showIconForWatchlist[i] ? 'inline' : 'none'
                        }"
                          class="tooltip-block"
                      >Save</span
                      >
                      <span
                          *ngIf="!showIconForWatchlist[i]"
                          class="tooltip-block"
                      >Remove from Saved</span
                      >
                    </span>
                    <span class="dropdown">
                      <span
                          class="img-with-tooltip"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                      >
                        <img
                            src="assets/images/knowledge-center/share.svg"
                            (mouseenter)="showTooltip = true"
                            (mouseleave)="showTooltip = false"
                            (click)="toggleTooltip()"
                            class="d-inline-block mobile-icons light-mode-icon"
                            style="width: 18px"
                        />
                        <img
                            src="../../../../../../assets/images/dark/share-dark-icon.png"
                            (mouseenter)="showTooltip = true"
                            (mouseleave)="showTooltip = false"
                            (click)="toggleTooltip()"
                            class="mobile-icons dark-mode-icon"
                            style="
                            width: 21px;
                            top: -1px;
                            position: relative;
                            left: 0px;
                          "
                        />
                        <span *ngIf="showTooltip" class="tooltip-block"
                        >share</span
                        >
                      </span>
                      <ul class="dropdown-menu for-custom-dropdown">
                        <li>
                          <span class="dropdown-item fontResize"
                          ><img
                              class="share-icon"
                              src="assets/images/general/Link.webp"
                          /><span class="action-icon">{{
                                  "products.home-fund-card-share-copy-btn"
                                      | translate
                            }}</span></span
                          >
                        </li>
                        <li>
                          <a class="dropdown-item fontResize" href="#">
                            <img
                                class="share-icon3"
                                src="assets/images/general/twitter-new-icon.webp"
                                style="width: 20px"
                            />
                            <span class="action-icon">{{
                                    "products.home-fund-card-share-twitter-btn"
                                        | translate
                              }}</span></a
                          >
                        </li>
                        <li>
                          <a class="dropdown-item fontResize" href="#">
                            <img
                                class="share-icon"
                                src="assets/images/general/FacebookLogo.webp"
                            />
                            <span class="action-icon">{{
                                    "products.home-fund-card-share-fb-btn" | translate
                              }}</span></a
                          >
                        </li>
                        <li>
                          <a class="dropdown-item fontResize" href="#">
                            <img
                                class="share-icon"
                                src="assets/images/general/LinkedinLogo.webp"
                            />
                            <span class="action-icon">{{
                                    "products.home-fund-card-share-linkedin-btn"
                                        | translate
                              }}</span></a
                          >
                        </li>
                        <li>
                          <a class="dropdown-item fontResize" href="#">
                            <img
                                class="share-icon"
                                src="assets/images/general/whatsapps.webp"
                            />
                            <span class="action-icon">{{
                                    "products.home-fund-card-share-whatsapp-btn"
                                        | translate
                              }}</span></a
                          >
                        </li>
                      </ul>
                    </span>
                  </div>
                </div>
                <div
                    type="button"
                    [routerLink]="slide?.url"
                    class="text-bold card-title mt-3 forlikecardtitle"
                >
                  {{ slide?.data?._source?.title }}
                </div>
              </div>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>

    <!-- start category filter-->
    <div class="FT-general-filter d-none">
      <div class="filter-box">
        <div class="filter-content">
          <h3>Filters</h3>
          <div class="filter-options">
            <div class="text-bold mb-3">Common Filters</div>
            <div class="form-check mb-2">
              <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Most viewed
              </label>
            </div>
            <div class="form-check mb-2">
              <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                5 min read
              </label>
            </div>
            <div class="form-check mb-2">
              <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Trending
              </label>
            </div>
            <div class="form-check mb-2">
              <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Content of the week
              </label>
            </div>
          </div>
          <div class="filter-options">
            <div class="text-bold mb-3">Content</div>
            <div class="form-check mb-2">
              <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Video
              </label>
            </div>
            <div class="form-check mb-2">
              <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Article
              </label>
            </div>
            <div class="form-check mb-2">
              <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Presentation
              </label>
            </div>
            <div class="form-check mb-2">
              <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Blog
              </label>
            </div>
            <div class="form-check mb-2">
              <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Podcast
              </label>
            </div>
            <div class="form-check mb-2">
              <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                E-Booklet
              </label>
            </div>
          </div>
          <div class="filter-options">
            <div class="text-bold mb-3">Categories</div>
            <div class="form-check mb-2">
              <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                New to Mutual Fund
              </label>
            </div>
            <div class="form-check mb-2">
              <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                New to Investing
              </label>
            </div>
            <div class="form-check mb-2">
              <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Women & Investing
              </label>
            </div>
            <div class="form-check mb-2">
              <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Blog
              </label>
            </div>
            <div class="form-check mb-2">
              <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Podcast
              </label>
            </div>
            <div class="form-check mb-2">
              <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
              />
              <label class="form-check-label" for="flexCheckDefault">
                E-Booklet
              </label>
            </div>
          </div>
        </div>
        <div class="filter-footer">
          <button class="btn btn-outline-primary">Cancel</button>
          <button class="btn btn-primary">Apply</button>
        </div>
      </div>
    </div>
  </div>
</section>
